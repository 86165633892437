/* colors
   please review current colors, do not duplicate them in another variable
*/
$ra-white: #fff;
$ra-black: #000;
$ra-factory-talk-hub-blue: #6a7c82;
$ra-secondary-text: #5d6063;
$ra-second-body-text: #707070;
$ra-card-logo-bg: #fbfbfb;
$ra-title-apps: #000000a3;
$ra-button-blue-hover: #235d9f;
$ra-button-app-dialog: #f1f4f6;
$ra-introduction-title: #6b6b6b;
$ra-primary-blue: #0f6fca;
$ra-focused-blue: #235d9f;
$ra-hover-blue: #d6e6ff;
$ra-btn-hover-blue: #1181d2;
$ra-disabled-grey: rgba(0, 0, 0, 56%);
$ra-disabled-fill-outline: rgba(0, 0, 0, 12%);
$ra-surface-grey: rgba(0, 0, 0, 4%);
