@mixin app-create-workspace-theme($theme, $custom-typography) {
  app-create-workspace {
    .create-workspace-container {
      .customize-title .static-text {
        @include ra-ui-typography-level-to-styles($custom-typography, headline);

        color: ra-ui-color($theme, foreground, text-label);
      }

      .customize-title .short-description {
        @include ra-ui-typography-level-to-styles($custom-typography, body-2);

        color: ra-ui-color($theme, foreground, text-label);
      }
    }
  }
}
