/* stylelint-disable value-list-comma-newline-after */
/* stylelint-disable declaration-colon-newline-after */
/* stylelint-disable selector-descendant-combinator-no-non-space */
/* stylelint-disable indentation */
/* stylelint-disable scss/at-extend-no-missing-placeholder, scss/at-import-no-partial-leading-underscore */
/* stylelint-disable selector-class-pattern */

@use "sass:math";
@use "@angular/material" as mat;
@use "../src/styles/helpers/colors";
@use "../src/styles/helpers/typography";

/* You can add global styles to this file, and also import other style files */
@import "../node_modules/@ra-web-tech-ui-toolkit/common-utils/themes/variables/_flourish";
@import "../node_modules/@ra-web-tech-ui-toolkit/cdk/styles";
@import "../node_modules/@ra-web-tech-ui-toolkit/common-utils/styles";
@import "../node_modules/@ra-web-tech-ui-toolkit/buttons/styles";
@import "../node_modules/@ra-web-tech-ui-toolkit/indicators/styles";
@import "../node_modules/@ra-web-tech-ui-toolkit/form-system/styles";
@import "../node_modules/@ra-web-tech-ui-toolkit/layout/styles";
@import "../node_modules/@ra-web-tech-ui-toolkit/lists/styles";
@import "../node_modules/@ra-web-tech-ui-toolkit/navigation/styles";
@import "../node_modules/@ra-web-tech-ui-toolkit/popups/styles";
@import "../node_modules/@ra-web-tech-ui-toolkit/grid/styles";
@import "../node_modules/@ra-web-tech-ui-toolkit/common-views/styles";
@import "./app/features/create-workspace/styles";

// --- WARN: These global imports are stale need to be localized ---
@import "../src/styles/helpers/variables";
@import "../src/styles/helpers/misc";
@import "../src/styles/helpers/sat-popover";

$twinstudio-theme: $flourish-theme;
$twinstudio-global-config: (
  // change to true to enable Windows-like cursor environment
  usewindowscursor: false
);

// $seed-app-custom-typography: $raider-typography;
$twinstudio-typography: ra-ui-typography-config(
  // change these values to alter the typography throughout the whole application
  $input: mat.define-typography-level(14px, 1.2, 400),
  $body-1: mat.define-typography-level(14px, 20px, 400, $letter-spacing: 0.25px),
  $button: mat.define-typography-level(14px, 22px, 400, $letter-spacing: 0.4px),
  $caption:
    mat.define-typography-level(14px, 20px, 400, $letter-spacing: 0.25px)
);

@include mat.core;
@include cdk-theme(
  $twinstudio-theme,
  $twinstudio-typography,
  $twinstudio-global-config
);
@include common-utils-theme(
  $twinstudio-theme,
  $twinstudio-typography,
  $twinstudio-global-config
);
@include buttons-theme(
  $twinstudio-theme,
  $twinstudio-typography,
  $twinstudio-global-config
);
@include indicators-theme(
  $twinstudio-theme,
  $twinstudio-typography,
  $twinstudio-global-config
);
@include form-system-theme(
  $twinstudio-theme,
  $twinstudio-typography,
  $twinstudio-global-config
);
@include layout-theme(
  $twinstudio-theme,
  $twinstudio-typography,
  $twinstudio-global-config
);
@include lists-theme(
  $twinstudio-theme,
  $twinstudio-typography,
  $twinstudio-global-config
);
@include navigation-theme(
  $twinstudio-theme,
  $twinstudio-typography,
  $twinstudio-global-config
);
@include popups-theme(
  $twinstudio-theme,
  $twinstudio-typography,
  $twinstudio-global-config
);
@include grid-theme(
  $twinstudio-theme,
  $twinstudio-typography,
  $twinstudio-global-config
);
@include common-views-theme($twinstudio-theme, $twinstudio-typography);
@include create-workspace-theme($twinstudio-theme, $twinstudio-typography);

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: roboto, "Helvetica Neue", sans-serif;
  background-color: #f1f4f6;
}

/* stylelint-disable-next-line selector-pseudo-element-no-unknown */
::ngdeep h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: roboto, "Helvetica Neue", sans-serif !important;
}

.app-container {
  height: 100%;
}

lib-common-mat-mdc-navbar .mat-mdc-drawer-content {
  overflow: visible;
}

.twin-studio-snack-bar button {
  color: #a9b8d1 !important;
}

/* Mat Dialog stuff */
.mat-mdc-dialog-container {
  overflow: hidden !important;
  box-shadow: none;
  outline: #f7fbfe solid 5px;
  border-radius: 4px;
}

.mat-mdc-dialog-no-outer-padding .mat-mdc-dialog-container {
  padding: 0;
}

/* You can add global styles to this file, and also import other style files */
:root {
  --ftdh-vault-proj-blue: #0f70ca;

  // Colors defined by UX
  // https://www.figma.com/file/tp9jcS5hEP7otXyKYs170E/Atomic-Design-Lemans?node-id=1275%3A13666
  --background: #f2f4f6;
  --primary: #0f70ca; // Most important element, i.e. button
  --primary-variant: #024199;
  --secondary: #b9dcf8; // Not as important, i.e. hover
  --secondary-variant: #0070cb;
  --neutral-color: #1a1c18; // High emphasis text and icons
  --neutral-color-variant: #949494;
  --disabled-titles-n-body: #848b8b;
  --app-text: #7c7e7e;
}

div.mat-mdc-expansion-panel-body {
  padding: 0 !important;
}

.cs-nav-logo {
  height: 24px !important;
  width: 136px;
}

.ra-logo-cs-nav-dark {
  @extend .cs-nav-logo;

  background: url("/assets/images/twin-studio-logo-white.svg") !important;
}

.ra-logo-cs-nav-light {
  @extend .cs-nav-logo;

  background: url("/assets/images/twin-studio-logo-black.svg") !important;
}

.pretty-scroll-container {
  overflow: auto;
  padding: 8px 0;
  border-top: 1px solid #d3d3d3;
  max-height: 77vh;
}

.pretty-scroll-container-small {
  overflow: auto;
  padding: 8px 0;
  border-top: 1px solid #d3d3d3;
  max-height: 65vh;
}

.container-fluid {
  padding-top: 4px;
}

.analysis-title {
  padding-top: 4px;
}

.pad-bottom-12px {
  padding-bottom: 12px;
}

// Flourish overrides - only use when they do not provide what we need
// Solution
.ra-icon-ide-md-diagram {
  // Note: at this time, Flourish's largest size is 14px medium icon
  font-size: 20px;
}

// Trash can
.ra-icon-ide-sm-delete {
  // Note: at this time, Flourish's small is larger than its medium and a different image
  font-size: 20px;
}

// Organization
.ra-icon-ide-lg-team {
  font-size: 20px;
}

// User
.ra-icon-ide-lg-user {
  font-size: 20px;
}

ra-ui-banner .ra-ui-banner-wrapper {
  width: 100%;
}

// Table styles used in Vault pages
.table-controls {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding-left: 8px;
}

.space-on-sides {
  padding: 0 16px 0 8px;
}

// Truncates table text with ellipsis and clamps the number of lines to show
.overflow {
  display: block;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
  white-space: pre-line;
}

.custom-filter {
  .mat-mdc-form-field-infix {
    margin-top: 14px !important;
  }
}

.container-custom-form {
  min-height: 500px;
  width: 100%;

  .custom-form {
    padding-top: 32px;

    .custom-field {
      width: 360px;
      display: flex;
      flex-flow: column-reverse;
    }

    .mat-mdc-form-field-flex {
      padding: 0 2px !important;
    }

    .mat-mdc-form-field-underline {
      height: 2px !important;
    }
  }
}

.custom-footer {
  width: 100vw;
  height: $footer-height;
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  background-color: #fff;
  z-index: 1000; // Keep footer the top layer

  .inner-footer {
    border-top: 2px solid #c9c9c9;
  }

  .button-footer {
    height: 38px;
    display: block;
    margin: 24px 12px;
  }
}

.mouse-pointer {
  cursor: pointer;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-row-start {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.flex-row-end {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.flex-row-stretch {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.full-width {
  width: 100%;
}

.space-between {
  justify-content: space-between;
}

.nowrap {
  flex-wrap: nowrap;
}

.wrap {
  flex-wrap: wrap;
}

.center-items {
  justify-content: center;
  align-items: center;
}

.center-verical {
  align-items: center;
}

.mat-mdc-select-value {
  padding-top: 4px !important;
}

.default-input-settings {
  min-width: 80px;
  padding-bottom: 6px !important;
  border-top: 0 !important;
}

.input-width-192px {
  .mat-mdc-form-field-infix {
    width: 184px !important;

    @extend .default-input-settings;
  }
}

.input-width-200 {
  .mat-mdc-form-field-infix {
    width: 200px !important;

    @extend .default-input-settings;
  }
}

.input-width-256px {
  .mat-mdc-form-field-infix {
    width: 256px !important;

    @extend .default-input-settings;
  }
}

.mat-mdc-ink-bar {
  background-color: var(--secondary-variant) !important;
}

.ag-header-group-cell-label {
  justify-content: center;
}

.multicast-group {
  background-color: rgb(219, 219, 219);
  color: black;
}

.text-enabled {
  color: var(--primary);

  &:hover {
    text-decoration: underline;
  }
}

.text-disabled {
  color: #d3d3d3;
}

.disabled-color {
  background-color: #d3d3d3;
}

.active-color {
  background-color: var(--primary);
}

.idh-snack-bar button {
  color: #a9b8d1 !important;
}

.mat-mdc-menu-content {
  button:hover {
    background-color: var(--secondary) !important;
  }

  button:active {
    background-color: #0f316b !important;
    color: #fff;
  }
}

.mat-mdc-menu-item-highlighted:not([disabled]) {
  button:hover {
    background-color: var(--secondary) !important;
  }

  button:active {
    background-color: #0f316b !important;
    color: #fff;
  }
}

.mat-mdc-primary
  .mat-mdc-option.mat-mdc-selected:not(.mat-mdc-option-disabled) {
  color: #fff;
}

.mat-mdc-option.mat-mdc-active {
  background: var(--secondary-variant) !important;
}

.mat-mdc-option:hover:not(.mat-mdc-option-disabled) {
  background: var(--secondary) !important;
  color: #000 !important;
}

.mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-checkmark-color: #d3d3d3;
  --mdc-checkbox-selected-hover-icon-color: var(--primary);
  --mdc-checkbox-selected-icon-color: var(--primary);
  --mdc-checkbox-selected-pressed-icon-color: var(--primary);
  --mdc-checkbox-disabled-selected-checkmark-color: rgba(0, 0, 0, 18%);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 38%);
  --mdc-checkbox-disabled-selected-icon-color: #d3d3d3;
  --mdc-checkbox-selected-focus-icon-color: transparent;
  --mdc-checkbox-selected-focus-hover-icon-color: rgba(0, 0, 0, 38%);

  &[readonly] {
    --mdc-checkbox-selected-checkmark-color: #d3d3d3;
  }
}

mat-checkbox {
  @mixin update-checkbox($color) {
    .mdc-checkbox__background {
      border-color: #848b8b !important;
    }

    .mdc-checkbox__checkmark-path {
      stroke: $color !important;
    }

    .mdc-checkbox__mixedmark {
      color: $color;
    }
  }

  .mat-ripple-element {
    background-color: transparent;
  }

  label {
    color: #d3d3d3;
  }

  @include update-checkbox(#d3d3d3);

  &[readonly] {
    @include update-checkbox(#d3d3d3);
  }

  &[disabled] {
    @include update-checkbox(#d3d3d3);
  }
}

app-tabs {
  .mat-mdc-tab-label {
    min-width: 25px !important;
  }
}

.mat-mdc-tooltip {
  font-size: 12px; // keep font size consistent
  min-width: fit-content; // prevent tooltip from truncating continuous words
}

mat-mdc-sidenav.hide-scroll {
  &.mat-mdc-sidenav .mat-mdc-drawer-inner-container {
    overflow: hidden !important;
  }
}

mat-mdc-sidenav-content.hide-scroll-content {
  &.mat-mdc-sidenav-content {
    overflow: hidden !important;
  }
}

// This is a temporary solution while we replace the current project list
.mat-mdc-dialog-content {
  overflow: visible !important;
}

mat-mdc-checkbox.center-check-label {
  label.mat-mdc-checkbox-layout {
    // centers checkbox vertically, default margin-bottom: 0.5rem;
    margin-bottom: unset !important;
  }
}

button.no-border-radius {
  &.mat-mdc-icon-button {
    // Sometimes default border-radius skews the shape of button icon
    border-radius: 0% !important;
  }
}

.side-nav-slider {
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 376px;
  background: #fff;
  box-shadow: -1px 0 8px rgba(0, 0, 0, 12%);
}

.storage-banner-flourish {
  display: flex;
  position: fixed;
  flex-flow: row nowrap;
  height: 40px;
  top: $navbar-height;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  cursor: pointer;
}

.ag-theme-material.controller-overview-details .ag-center-cols-container {
  width: 100% !important;
}

.ag-theme-material.controller-overview-details .ag-cell-wrapper {
  padding-left: 8px;
}

.ag-theme-material.controller-overview-details .ag-row {
  font-size: 14px !important;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
}

.ag-theme-material.controller-overview-details .ag-row-hover {
  background-color: #f9f9f9 !important;
  border-left: 3px solid var(--primary);
  border-right: 3px solid var(--primary);
}

.ag-theme-material.controller-overview-details .ag-body-viewport {
  background-color: #fff !important;
}

.de-edit-container .vault-tree-flex-container {
  max-height: 55vh !important;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  transition: 0.3s;
  margin-left: 231px;
}

// Remove top border from ng-select value (one shown when the droplist is collapsed)
.ng-select .ng-select-container .ng-value-container {
  border-top: 0;
}

// ng-select's too aggressive in their line-height - this fixes things.
// Also, match our current color scheme.
.ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  line-height: 1em;
  padding: 12px;

  &.ng-option-marked {
    background-color: var(--secondary);
  }

  &.ng-option-selected {
    background-color: var(--primary);

    // This stinks, but encapsulation leaves us no choice.
    // When hovering over a dropdown item
    p,
    div {
      color: white;
    }
  }
}

.primary-mat-mdc-button {
  align-items: center;
  background-color: colors.$ra-primary-blue;
  box-shadow: none !important;
  color: colors.$ra-white;
  font-family: typography.$font-family;

  &:disabled {
    background-color: colors.$ra-disabled-fill-outline;
    border: 0;
    color: colors.$ra-disabled-grey;
  }
}

.primary-mat-mdc-button:hover:enabled {
  background: colors.$ra-btn-hover-blue !important;
  border-radius: 4px !important;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 14%),
    0 1px 10px colors.$ra-disabled-fill-outline, 0 2px 4px rgba(0, 0, 0, 20%) !important;
  color: colors.$ra-white;
}

.primary-mat-mdc-button:active:enabled {
  background: colors.$ra-btn-hover-blue !important;
  border-radius: 4px !important;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 14%),
    /* stylelint-disable-next-line value-list-comma-newline-after */ 0 1px 18px
      colors.$ra-disabled-fill-outline,
    0 3px 5px rgba(0, 0, 0, 20%) !important;
  color: colors.$ra-white;
}

.app-navbar-container {
  height: 100%;
}

.app-hide-navbar-container {
  height: calc(100vh + 54px);
  margin-top: -55px;
  position: absolute;
  width: 100%;
}

.mat-mdc-tab-header {
  width: fit-content;
}

.mat-drawer-content {
  height: fit-content !important;
}

// Manipulate initial background color
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent;
  height: 53px;
  width: 325px;
}

// Manipulate background color on hover and focus
.mat-mdc-form-field-focus-overlay {
  background-color: transparent !important;
  height: 53px;
  width: 325px;
}

.header-basket-table {
  color: #5d6063;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.25px;
}

.header-tier-actions {
  color: #5d6063;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.25px;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.workstation-name {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 235px;
}

.workstation-cost {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
}

.workstation-tier {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.cell-header-checkbox {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.header-checkbox {
  margin-top: 5px;
}

.cell-text-push-vault {
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  text-align: left;
  justify-content: flex-start;
  width: 100%;
  background-color: white;
  color: #000;
}
