@mixin app-finish-theme($theme, $custom-typography) {
  .fn-container {
    .fn-content {
      .fn-title {
        @include ra-ui-typography-level-to-styles($custom-typography, headline);
      }

      .fn-description {
        @include ra-ui-typography-level-to-styles($custom-typography, body-2);
      }
    }
  }
}
