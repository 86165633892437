@mixin app-quota-failed-theme($theme, $custom-typography) {
  .content {
    .title {
      @include ra-ui-typography-level-to-styles($custom-typography, headline);
    }

    .description {
      @include ra-ui-typography-level-to-styles($custom-typography, body-2);
    }
  }
}
