@mixin app-workspace-information-theme($theme, $custom-typography) {
  app-workspace-information .workspace-info-container {
    .workspace-title {
      i {
        @include ra-ui-typography-level-to-styles($custom-typography, body-3);
      }

      .static-text {
        @include ra-ui-typography-level-to-styles($custom-typography, headline);
      }
    }

    .workspace-form {
      .row-title i,
      .row-title .static-text .static-text-inner {
        @include ra-ui-typography-level-to-styles($custom-typography, subheading-2-bold);
      }

      .row-description .static-text .static-text-inner {
        @include ra-ui-typography-level-to-styles($custom-typography, body-2);
      }
    }
  }
}
