@mixin app-summary-theme($theme, $custom-typography) {
  app-summary {
    .sm-container {
      .sm-title {
        i {
          @include ra-ui-typography-level-to-styles($custom-typography, body-3);
        }

        .static-text {
          @include ra-ui-typography-level-to-styles($custom-typography, headline);
        }
      }

      .sm-content {
        .left-content {
          .sm-info {
            .name-container .name-label,
            .region-container .region-label {
              @include ra-ui-typography-level-to-styles($custom-typography, form-field-text);

              color: ra-ui-color($theme, foreground, text-label);
            }

            .name-container .name-value,
            .region-container .region-value {
              @include ra-ui-typography-level-to-styles($custom-typography, input);
            }
          }
        }

        .right-content {
          background-color: ra-ui-color($theme, background, interactive-component-03);

          .token-container {
            .token-label {
              @include ra-ui-typography-level-to-styles($custom-typography, title);
            }

            .token-description {
              @include ra-ui-typography-level-to-styles($custom-typography, body-2);

              color: ra-ui-color($theme, foreground, text-label);
            }

            .token-value {
              @include ra-ui-typography-level-to-styles($custom-typography, headline-bold);

              color: ra-ui-color($theme, foreground, text-label);
            }
          }
        }
      }
    }
  }
}
