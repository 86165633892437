/* stylelint-disable selector-no-vendor-prefix */
body,
html {
  overflow-x: hidden;
  padding-right: 0 !important; // resets padding right added by Bootstrap modal
}

*:-moz-full-screen,
*:-webkit-full-screen,
*:fullscreen *:-ms-fullscreen {
  overflow: auto !important;
}

.page-body-wrapper {
  height: calc(100vh - #{$navbar-height});
  display: flex;
  flex-direction: row;
  padding-left: 0;
  padding-right: 0;

  &.full-page-wrapper {
    width: 100%;
    min-height: 100vh;
  }
}

.detail-page-body-wrapper {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  padding-right: 0;

  &.full-page-wrapper {
    width: 100%;
    min-height: 100vh;
  }
}

.main-panel {
  /* stylelint-disable-next-line declaration-colon-newline-after */
  /* stylelint-disable-next-line max-line-length */
  transition: width $action-transition-duration $action-transition-timing-function, margin $action-transition-duration $action-transition-timing-function;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  background-color: var(--background);

  @media (max-width: 991px) {
    width: 100%;
  }
}

.content-wrapper {
  background: $content-bg;
  padding: 0 1.7rem; // do not use relative on top/bottom for nicer scrolling behavior controlled by content
  width: 100%;
  flex-grow: 1;
  overflow-y: visible;
}

.detail-content-wrapper {
  background: white;
  padding: 1.5rem 1.5rem 0;
  width: 100%;
  flex-grow: 1;
}

.container-scroller {
  overflow: hidden;
}

.scroll-container {
  position: relative;

  &.horizontally {
    overflow-x: hidden;
    width: 100%;
    max-width: 100%;
  }

  &.vertically {
    overflow-y: hidden;
    height: 100%;
    max-height: 100%;
  }
}

pre {
  background: color(gray-lighter);
  padding: 15px;
  font-size: 14px;
  font-family: $type-1;
}
